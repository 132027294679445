<template>
  <el-tabs v-model="active" @tab-click="getInfo()">
    <el-tab-pane label="消息设置" name="0" class="descriptionsBox">
      <el-descriptions title="藏品征集审核" :column="2" class="m_b2">
        <el-descriptions-item label="审核处理通知" v-if="info.collect.audit">
          <el-radio-group v-model="info.collect.audit.remind">
            <el-radio label="0">开启</el-radio>
            <el-radio label="1">关闭</el-radio>
          </el-radio-group>
        </el-descriptions-item>
        <el-descriptions-item label="审核结果通知" v-if="info.collect.result">
          <el-radio-group v-model="info.collect.result.remind">
            <el-radio label="0">开启</el-radio>
            <el-radio label="1">关闭</el-radio>
          </el-radio-group>
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions title="藏品入库审核" :column="2" class="m_b2">
        <el-descriptions-item label="审核处理通知" v-if="info.storage.audit">
          <el-radio-group v-model="info.storage.audit.remind">
            <el-radio label="0">开启</el-radio>
            <el-radio label="1">关闭</el-radio>
          </el-radio-group>
        </el-descriptions-item>
        <el-descriptions-item label="审核结果通知" v-if="info.storage.result">
          <el-radio-group v-model="info.storage.result.remind">
            <el-radio label="0">开启</el-radio>
            <el-radio label="1">关闭</el-radio>
          </el-radio-group>
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions title="藏品出库审核" :column="2" class="m_b2">
        <el-descriptions-item label="审核处理通知" v-if="info.removal.audit">
          <el-radio-group v-model="info.removal.audit.remind">
            <el-radio label="0">开启</el-radio>
            <el-radio label="1">关闭</el-radio>
          </el-radio-group>
        </el-descriptions-item>
        <el-descriptions-item label="审核结果通知" v-if="info.removal.result">
          <el-radio-group v-model="info.removal.result.remind">
            <el-radio label="0">开启</el-radio>
            <el-radio label="1">关闭</el-radio>
          </el-radio-group>
        </el-descriptions-item>
      </el-descriptions>
      <el-button class="saveBtn" type="primary" @click="submitMessageSet()" size="small">保存</el-button>
    </el-tab-pane>
    <el-tab-pane label="审核设置" name="1">
      <Audit :activeTab="1" v-if="active == 1"></Audit>
    </el-tab-pane>
    <el-tab-pane label="上传设置" name="2" class="descriptionsBox">
      <el-form size="small" :model="inputForm" label-width="210px" ref="inputForm"
               v-loading="loading">
        <div v-for="(item, index) in inputForm.uploadList" :key="index">
          <el-descriptions :title="item.businessName"></el-descriptions>
          <el-form-item :prop="`uploadList.${index}.uploadNum`"
                        :rules="[{required: item.required == 0 ? true : false, message: '数量上限不能为空', trigger: 'blur'}, {validator: validator.numLimit, trigger: 'blur'}]"
                        :label="'上传' + item.businessName + '图片数量上限：'">
            <el-input v-model.trim="item.uploadNum" maxlength="2"
                      :placeholder="'请输入上传' + item.businessName + '图片数量上限（1~99）'"
                      clearable style="width: 60%"
                      @input="item.uploadNum = item.uploadNum.replace(/[^\d]/g, '')"></el-input>
          </el-form-item>
        </div>
        <el-form-item>
          <el-button class="saveBtn" type="primary" @click="submit()" size="small">保存</el-button>
        </el-form-item>
      </el-form>
    </el-tab-pane>
    <el-tab-pane label="导入设置" name="3" class="descriptionsBox">
      <el-descriptions title="导入设置" :column="2" class="m_b2">
        <el-descriptions-item label="藏品登记导入设置" v-if="importSettings.exportWay">
          <el-radio-group v-model="importSettings.exportWay">
            <el-radio :label="1">新增导入</el-radio>
            <el-radio :label="2">覆盖导入</el-radio>
          </el-radio-group>
        </el-descriptions-item>
      </el-descriptions>
      <el-button class="saveBtn" type="primary" @click="submItimportSet()" size="small">保存</el-button>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import Audit from './audit'

export default {
  components: {
    Audit
  },
  data() {
    return {
      loading: false,
      active: '0',
      info: {
        collect: {},
        storage: {},
        removal: {},
      },
      inputForm: {
        uploadList: [],
      },
      importSettings:{},
    }
  },
  mounted() {
    this.getInfo()
  },
  methods: {
    getInfo() {
      if (this.active == 0) {
        this.getMessageSet()
      }
      if (this.active == 2) {
        this.getUploadSet()
      }
      if (this.active == 3) {
        this.getImportSettings()
      }
    },
    // 获取消息设置
    getMessageSet() {
      this.loading = true
      this.$axios(this.api.auth.getByMessageType, {
        systemType: '1',
        messageType: ['0', '1', '2']
      }, 'post').then(data => {
        if (data.data && data.status) {
          let collect = {}, storage = {}, removal = {}
          data.data.map(item => {
            switch (item.messageType) {
              case "0":
                if (item.notificationType == '0') {
                  collect.audit = item
                }
                if (item.notificationType == '1') {
                  collect.result = item
                }
                break;
              case "1":
                if (item.notificationType == '0') {
                  storage.audit = item
                }
                if (item.notificationType == '1') {
                  storage.result = item
                }
                break;
              case "2":
                if (item.notificationType == '0') {
                  removal.audit = item
                }
                if (item.notificationType == '1') {
                  removal.result = item
                }
                break;
            }
          })
          this.$nextTick(() => {
            this.$set(this.info, 'collect', collect)
            this.$set(this.info, 'storage', storage)
            this.$set(this.info, 'removal', removal)
            this.loading = false
          })
        }
      })
    },
    // 保存消息设置
    submitMessageSet() {
      let p = [], obj = []
      for (let key in this.info) {
        p.push(this.info[key])
      }
      p.map(item => {
        for (let key in item) {
          obj.push(item[key])
        }
      })
      this.$axios(this.api.auth.updateByType, obj, 'put').then(data => {
        if (data.status) {
          this.$message.success('提交成功')
        } else {
          this.$message.error('提交失败')
        }
        this.getMessageSet()
      })
    },
    // 获取上传设置
    getUploadSet() {
      this.loading = true
      this.$axios(this.api.auth.businesssetting).then(data => {
        if (data.status) {
          this.inputForm.uploadList = data.data
          this.loading = false
        }
      })
    },
    // 保存
    submit() {
      if (this.active == 2) {
        this.submitUploadSet()
      }
    },
    // 保存上传设置
    submitUploadSet() {
      this.$refs['inputForm'].validate((valid) => {
        if (valid) {
          this.loading = true
          this.$axios(this.api.auth.businesssettingUpdateById, this.inputForm.uploadList, 'post').then(data => {
            if (data.status) {
              this.$message.success('保存成功')
            } else {
              this.$message.error('保存失败')
            }
            this.getUploadSet()
            this.loading = false
          })
        }
      })
    },

    //获取导入设置
    getImportSettings(){
      this.$axios(this.api.auth.exportSettingInfo).then(data => {
        if (data.status) {
          this.importSettings = data.data
        }
      })
    },

    //保存导入设置
    submItimportSet(){
      this.$axios(this.api.auth.exportSettingUpdate, {
        exportWay:this.importSettings.exportWay
      }).then(data => {
        if (data.status) {
          this.$message.success('保存成功')
        } else {
          this.$message.error('保存失败')
        }
        this.getImportSettings()
      })
    },
  }
}
</script>

<style scoped>
.descriptionsBox {
  padding: 20px;
  overflow-y: auto;
  height: calc(100vh - 300px);
}

.descriptionsBox >>> .el-radio-group {
  margin-top: 4px !important;
}

.saveBtn {
  position: fixed;
  bottom: 20px;
  left: 55%;
}
</style>
